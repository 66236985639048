import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getHeaders, postRequestWrapper, request } from 'app/utils/fetch';
import { getJwt } from 'app/crud/auth.crud';
import { actions as projectsActions } from '../projects/actions';
import { actions } from './actions';
import { actionTypes } from './actionTypes';
import stringify from 'qs-stringify';

export function* watchKeywordTagsSaga() {
  yield takeLatest(actionTypes.FetchUserTags, apiFetchUserTagsSaga);
  yield takeLatest(actionTypes.AddTags, apiAddTagsSaga);
  yield takeLatest(actionTypes.DeleteTag, apiDeleteTagSaga);
  yield takeLatest(actionTypes.UpdateTag, apiUpdateTagSaga);
}

function* apiFetchUserTagsSaga(action) {
  yield put(actions.setLoadingTag(true));
  const requestPayload = {
    project_id: action.payload.projectId,
  };
  const headers = yield getHeaders();
  const apiRes = yield call(() => request(headers).get('/keyword-tags', { params: requestPayload }));
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.setUserTags(apiRes.data));
  }
  yield put(actions.setLoadingTag(false));
}

function* apiAddTagsSaga(action) {
  yield put(actions.setAddingTag(true));
  const requestPayload = {
    tags: action.payload.tags,
  };
  const token = yield getJwt();
  const apiRes = yield postRequestWrapper('/keyword-tags', token, requestPayload);
  if (apiRes && apiRes.status === 'OK') {
    if (_.isFunction(action.cb)) {
      action.cb(null);
    }
    yield put(actions.addUserTags(apiRes.data));
  }
  yield put(actions.setAddingTag(false));
}

function* apiDeleteTagSaga(action) {
  const requestPayload = {
    project_id: action.payload.projectId,
  };
  const headers = yield getHeaders();
  const path = `/keyword-tags/${action.payload.tagId}?${stringify(requestPayload)}`;
  const apiRes = yield call(() => request(headers).delete(path));
  if (apiRes && apiRes.status === 'OK') {
    yield put(actions.removeTag(apiRes.data));
    yield put(projectsActions.deleteTagsForAllKeywords(apiRes.data));
    if (_.isFunction(action.cb)) {
      action.cb(null);
    }
  }
}

function* apiUpdateTagSaga(action) {
  yield put(actions.setAddingTag(true));
  const requestPayload = {
    tag: action.payload.tag,
    colour: action.payload.colour,
    pin: action.payload.pin,
  };
  const headers = yield getHeaders();
  const path = `/keyword-tags/${action.payload.id}`;
  const apiRes = yield call(() => request(headers).put(path, stringify(requestPayload)));
  // const apiRes = yield postRequestWrapper('/keyword-tags/update', token, requestPayload);
  if (apiRes && apiRes.status === 'OK') {
    if (_.isFunction(action.cb)) {
      action.cb(null);
    }
    yield put(actions.updateUserTag(apiRes.data));
    yield put(projectsActions.updateTagsForAllKeywords(apiRes.data));
  }
  yield put(actions.setAddingTag(false));
}
